import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Typography from "@material-ui/core/Typography";

// var gaProperty = 'UA-172422653-1';
// var disableStr = 'ga-disable-' + gaProperty;
// if (document.cookie.indexOf(disableStr + '=true') > -1) {
//     window[disableStr] = true;
// }
// function gaOptout() {
//     document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
//     window[disableStr] = true;
//     // alert('Das Tracking ist jetzt deaktiviert');
// }

const Imprint = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Typography variant="h5" component="h1" gutterBottom>
      Datenschutzerklärung
    </Typography>
    <Typography variant="body1" gutterBottom>
      Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
      <br />
      <br />
      MH3 GmbH <br />
      Lichtensteinstrasse 45 <br />
      72124 Pliezhausen
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Ihre Betroffenenrechte
      </Typography>
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
      <br />
      <ul>
        <li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,
        </li>
        <li>Berichtigung unrichtiger personenbezogener Daten</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten</li>
        <li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
          gesetzlicher Pflichten noch nicht löschen dürfen
        </li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
        <li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
          haben oder einen Vertrag mit uns abgeschlossen haben.
        </li>
      </ul>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen.
      <br />
      <br />
      Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige
      Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich
      nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen
      Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
      Bereich) mit Anschrift finden Sie unter:
      https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte
      </Typography>
      Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
      Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
      persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet
      nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
      <br />
      <ul>
        <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
        <li>
          die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich
          ist,
        </li>
        <li>
          die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich ist,
        </li>
      </ul>
      die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und
      kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
      Interesse an der Nichtweitergabe Ihrer Daten haben.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Löschung bzw. Sperrung der Daten
      </Typography>
      Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit.
      Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies
      zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die
      vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach
      Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die
      entsprechenden Daten routinemäßig und entsprechend den gesetzlichen
      Vorschriften gesperrt oder gelöscht.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Erfassung allgemeiner Informationen beim Besuch unserer Website
      </Typography>
      Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines
      Cookies Informationen allgemeiner Natur erfasst. Diese Informationen
      (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
      Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und
      ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche
      keine Rückschlüsse auf Ihre Person zulassen.
      <br />
      <br />
      Diese Informationen sind technisch notwendig, um von Ihnen angeforderte
      Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des
      Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken
      verarbeitet:
      <br />
      <ul>
        <li>
          Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
        </li>
        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zu weiteren administrativen Zwecken.</li>
      </ul>
      Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem
      berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir
      verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen.
      Empfänger der Daten sind nur die verantwortliche Stelle und ggf.
      Auftragsverarbeiter.
      <br />
      <br />
      Anonyme Informationen dieser Art werden von uns ggfs. statistisch
      ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik
      zu optimieren.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Cookies
      </Typography>
      Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
      Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre
      Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte
      Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre
      Verbindung zum Internet.
      <br />
      <br />
      Cookies können nicht verwendet werden, um Programme zu starten oder Viren
      auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen
      Informationen können wir Ihnen die Navigation erleichtern und die korrekte
      Anzeige unserer Webseiten ermöglichen. In keinem Fall werden die von uns
      erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine
      Verknüpfung mit personenbezogenen Daten hergestellt.
      <br />
      <br />
      Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
      betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
      Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies
      jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte
      verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren,
      wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass
      einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
      wenn Sie die Verwendung von Cookies deaktiviert haben.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Kontaktformular
      </Typography>
      Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit
      uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre
      freiwillige Einwilligung. Hierfür ist die Angabe einer validen
      E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der
      anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist
      optional. Die von Ihnen gemachten Angaben werden zum Zwecke der
      Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
      Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene
      Daten automatisch gelöscht.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Verwendung von Google Maps
      </Typography>
      Diese Webseite verwendet Google Maps API, um geographische Informationen
      visuell darzustellen. Bei der Nutzung von Google Maps werden von Google
      auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben,
      verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung
      durch Google können Sie den Google-Datenschutzhinweisen entnehmen. Dort
      können Sie im Datenschutzcenter auch Ihre persönlichen
      Datenschutz-Einstellungen verändern. Ausführliche Anleitungen zur
      Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden
      Sie hier.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Eingebettete YouTube-Videos
      </Typography>
      Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber der
      entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
      CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird
      eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube
      mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Account
      eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich
      zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem
      Youtube-Account ausloggen.
      <br />
      <br />
      Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die
      Hinweise über das Nutzerverhalten sammeln.
      <br />
      <br />
      Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat,
      wird auch beim Anschauen von Youtube-Videos mit keinen solchen Cookies
      rechnen müssen. Youtube legt aber auch in anderen Cookies
      nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies
      verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.
      <br />
      <br />
      Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der
      Datenschutzerklärung des Anbieters unter:
      https://www.google.de/intl/de/policies/privacy/
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Google Analytics
      </Typography>
      <p>
        Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website
        Google Analytics eingesetzt, ein Webanalysedienst der Google Ireland
        Limited (&bdquo;Google&ldquo;). Die Nutzung umfasst die Betriebsart
        &bdquo;Universal Analytics&ldquo;. Hierdurch ist es möglich, Daten,
        Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen
        User-ID zuzuordnen und so die Aktivitäten eines Nutzers
        geräteübergreifend zu analysieren.
      </p>
      <p>
        Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien, die
        auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
        der Website durch Sie ermöglichen. Die durch das Cookie erzeugten
        Informationen über Ihre Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert. Im
        Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird
        Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum zuvor gekürzt. Wir weisen Sie darauf
        hin, dass auf dieser Webseite Google Analytics um eine IP-Anonymisierung
        erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog.
        IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von
        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt. Nähere Informationen zu Nutzungsbedingungen und
        Datenschutz finden Sie unter{" "}
        <a
          href="https://www.google.com/analytics/terms/de.html"
          target="_blank" rel="noreferrer"
        >
          https://www.google.com/analytics/terms/de.html
        </a>{" "}
        bzw. unter{" "}
        <a href="https://policies.google.com/?hl=de" target="_blank" rel="noreferrer">
          https://policies.google.com/?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Zwecke der Verarbeitung</strong>
        <br />
        Im Auftrag des Betreibers dieser Website wird Google diese Informationen
        benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen.
      </p>
      <p>
        <strong>Rechtsgrundlage</strong>
        <br />
        Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre
        Einwilligung gemäß{" "}
        <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank" rel="noreferrer">
          Art. 6 Abs. 1 S.1 lit. a DSGVO
        </a>
        .
      </p>
      <p>
        <strong>Empfänger / Kategorien von Empfängern</strong>
        <br />
        Der Empfänger der erhobenen Daten ist Google.
      </p>
      <p>
        <strong>Übermittlung in Drittstaaten</strong>
        <br />
        Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf
        Grundlage des Angemessenheitsbeschlusses der Europäischen Kommission in
        die USA übermittelt. Das Zertifikat können Sie{" "}
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
          target="_blank" rel="noreferrer"
        >
          hier
        </a>{" "}
        abrufen.
      </p>
      <p>
        <strong>Dauer der Datenspeicherung</strong>
        <br />
        Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID)
        oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch
        gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist,
        erfolgt automatisch einmal im Monat.
      </p>
      <p>
        <strong>Betroffenenrechte</strong>
        <br />
        Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft
        widerrufen, indem Sie die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      </p>
      <p>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
        und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem Sie das{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank" rel="noreferrer"
        >
          Browser-Add-on
        </a>{" "}
        herunterladen und installieren. Opt-Out-Cookies verhindern die
        zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die
        Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu
        verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen
        durchführen.
        {/* Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt:{" "}
        <a href="#" onClick={gaOptout}>
          <strong>Google Analytics deaktivieren</strong>
        </a> */}
      </p>
      <Typography variant="h6" component="h1" gutterBottom>
        Änderung unserer Datenschutzbestimmungen
      </Typography>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
      <br />
      <br />
      <Typography variant="h6" component="h1" gutterBottom>
        Fragen an den Datenschutzbeauftragten
      </Typography>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an die für den Datenschutz verantwortliche
      Person in unserer Organisation.
    </Typography>
  </Layout>
);

export default Imprint;
